import A from "axios";
import { v4 as uuidv4 } from "uuid";
import md5 from "crypto-js/md5";
// import envConfig from "../utils/envConfig";
import { Loading } from "element-ui";
import { Toast } from 'vant';
import router from '@/router'

var loading = null;

const axios = A.create({
  // baseURL: "/api",
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    clientType: "WAP",
    terminal: "WAP",
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwtToken");
    if (token && token !== "null" && token !== "undefined") {
      config.headers["Authorization"] = token;
    }
    loading = Loading.service({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    loading.close();
    return response;
  },
  (error) => {
    if (error.response.status === 401) {//未登录
      router.push('/login')
    }
   // Toast('系统错误,请稍候再试');
    return {};
  }
);

const showTips = (data) => {
  if (data?.msg) {
    Toast(data?.msg, data?.code, 5000);
  }
};
const toLogin = (data) => {
  router.push('/login')
};
//=========产品=================================
export const selectProductList = async (reqbody) => {
  const body = reqbody;
  const { data } = await axios.post("/api/product/selectProductList", body);
  console.log(+data?.code+"++++")
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }else if(+data?.code ==401){
    toLogin();
  }
  showTips(data);
};

export const selectProductDetail = async (reqbody) => {
  const body = reqbody;
  const { data } = await axios.post("/api/product/selectProductDetail", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const productFilePreview = async (reqbody) => {
  const body = reqbody;
  const { data } = await axios.post("/api/product/employeeTreasure/productFilePreview", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const reserveMatch = async (reqbody) => {
  const body = reqbody;
  const { data } = await axios.post("/api/product/employeeTreasure/reserveMatch", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const checkUserIsFinancial = async (reqbody) => {
  const body = reqbody;
  const { data } = await axios.post("/api/product/employeeTreasure/checkUserIsFinancial", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const allSignList = async (reqbody) => {//签约列表
  const body = reqbody;
  const { data } = await axios.post("/api/product/front/allSignList", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }else if(+data?.code ==401){
    toLogin();
  }
  showTips(data);
};

export const canaelReserve = async (reqbody) => {//取消签约
  const body = reqbody;
  const {data } = await axios.post("/api/product/front/canaelReserve", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const cancelLine = async (reqbody) => {//取消排队
  const body = reqbody;
  const {data } = await axios.post("/api/product/commonsign/signReserver/cancelLine", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const getInitProduct = async (reqbody) => {//签约详情初始化
  const body = reqbody;
  const {data } = await axios.post("/api/product/sign/getInitProduct", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const getInitUserReserve = async (reqbody) => {//签约初始化
  const body = reqbody;
  console.log(body)
  const { data } = await axios.post("/api/product/sign/getInitUserReserve", body);
    const res = data;
    return res;
};

export const selectBankCardList = async (reqbody) => {//签约银行卡选择
  const body = reqbody;
  const { data } = await axios.post("/api/product/front/selectBankCardList", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const getProtocolPreview = async (reqbody) => {//签约协议预览
  const body = reqbody;
  const { data } = await axios.post("/api/product/sign/getProtocolPreview", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const doSign = async (reqbody) => {//确认签约
  const body = reqbody;
  console.log(body)
  const { data } = await axios.post("/api/product/sign/doSign", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const getRiskAssessmentInfo = async (reqbody) => {//获取风险测评配置信息
  const body = reqbody;
  const { data } = await axios.post("/api/product/front/getRiskAssessmentInfo", body);
  if (+data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const saveRiskEvaluationResult = async (reqbody) => {//风险测评结果保存
  const body = reqbody;
  const { data } = await axios.post("/api/product/front/saveRiskEvaluationResult", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const isRiskLevel = async (reqbody) => {//风险测评等级是否匹配
  const body = reqbody;
  const { data } = await axios.post("/api/product/sign/isRiskLevel", body);
    const res = data;
    return res;
};

export const repaymentAlert = async (reqbody) => {//兑付提醒
  const body = reqbody;
  const { data } = await axios.post("/api/product/front/repaymentAlert", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const myAccount = async (reqbody) => {//我的账户
  const body = reqbody;
  const { data } = await axios.post("/api/product/front/myAccount", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }else if(+data?.code ==401){
   toLogin();
  }
  showTips(data);
};

export const getAlreadySignDetail = async (reqbody) => {//
  const body = reqbody;
  const { data } = await axios.post("/api/product/sign/getAlreadySignDetail", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const productRaybackList = async (reqbody) => {//分配计划
  const body = reqbody;
  const { data } = await axios.post("/api/product/productRaybackList", body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const getUserManagerByUserId = async (reqbody) => {//分配计划
  const body = reqbody;
  const { data } = await axios.post("/api/crm/getUserManagerByUserId?userId=",body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const upload = async (reqbody) => {//凭证上传
  const body = reqbody;
  const { data } = await axios.post("/api/product/audit/upload",body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};

export const getImgAuditInfo = async (reqbody) => {//凭证回显
  const body = reqbody;
  const { data } = await axios.post("/api/product/audit/getImgAuditInfo",body);
  if ( +data?.code === 1) {
    const res = data;
    return res;
  }
  showTips(data);
};



































