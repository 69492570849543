<template>
  <div class="paymentPlan">
      <div class="paymentPlanTop">
        <ul>
          <li>
            <p class="cashedAmount">{{infoData.cashedAmount}}</p>
            <p>已分配总金额(元)</p>
          </li>
          <li>
            <p class="unpaidAmount">{{infoData.unpaidAmount}}</p>
            <p>待分配总金额(元)</p>
          </li>
        </ul>
        <p>*待分配总金额（以实际收益为准）</p>
        <div class="rate">
          <p class="rateNum">({{infoData.cashedPeriodCount}}/{{infoData.totalPeriodCount}})</p>
          <span :style="{width:infoData.rate+'%'}"></span>
        </div>
      </div>
      <div class="paymentPlanCon">
        <table class="paymentPlanConTr">
          <tbody><tr>
            <th>期数</th>
            <th>分配日</th>
            <th>收益结算日</th>
            <th>金额</th>
            <th>状态</th>
          </tr>
          <tr v-for="item in ListData">
            <td>{{item.period}}</td>
            <td>{{ item.actualDueDate | formateDate }}</td>
            <td>{{ item.periedSettlementDate | formateDate}}</td>
            <td>{{item.principalPayableInterest}}</td>
            <template v-if="item.repaymentStatus ==0">
              <td>未分配</td>
            </template>
            <template v-if="item.repaymentStatus ==1">
              <td>正常分配</td>
            </template>
            <template v-if="item.repaymentStatus ==2">
              <td>提前分配</td>
            </template>
          </tr>
         </tbody></table>
      </div>
      <p class="paymentPlanTips" @click="tips()">温馨提示</p>
    </div>
</template>
<script>
import { Dialog } from 'vant';
import { productRaybackList } from "../services/proApi";
export default {
  data() {
    return {
     infoData:{},
     ListData:{},
    };
  },
  created: function() {
    var _this = this;
    var reserveId=_this.$route.query.reserveId;
    _this.reserveId=reserveId;
    _this.productRaybackList()
  },
  methods: {
     async productRaybackList(){
        let _this=this;
        const datas = await productRaybackList({reserveId: this.reserveId});
        if (datas.code === 1) {
            _this.infoData=datas.data;
            _this.ListData=datas.data.plans;
        }
   },
   tips(){
      let msg="1、若分配方式为到期一次性分配本金及收益，则产品到期日起三个工作日内一次性分配投资本金及总收益<br />2、若分配方式为按月分配收益，到期分配本金，则在每期分配日支付一个自然月的收益，分配日遇节假日进行相关调整，产品到期日起三个工作日内分配投资本金及剩余收益<br />3、若分配方式为按季分配收益，到期分配本金，则在每期分配日支付三个自然月的收益，分配日遇节假日进行相关调整，产品到期日起三个工作日内分配投资本金及剩余收益<br />4、收益结算日为每一期收益计算截止日期"
      Dialog.alert({
        title: '温馨提示',
        confirmButtonText:'我知道了',
        message:msg,
      })
      .then(() => { })
   }
  },
   filters:{
        formateDate:function(now){
          var now = parseInt(now);
          var d = new Date(now);
          return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()
        },  
    }, 
};
</script>

<style scoped lang="scss">

</style>
<style lang='css'>
.paymentPlan{font-size: 0.8rem;}
.paymentPlanTop{
  background: #4086F5;
  color:#fff;
  padding-top:1rem;
  text-align: center;
  position: relative;
  padding-bottom:1rem;
 }
.paymentPlanTop li{display: inline-block;text-align: center;width: 49%;margin: 1.5rem 0;}
.paymentPlanTop li:last-child{border-left:0.01rem solid #fff;}
.paymentPlanTop li p:first-child{font-size:0.9rem; padding-bottom:0.5rem;}
.rate .rateNum{text-align: right;padding-right: 1rem;}
.rate span{background: #003EA1;position: absolute;bottom: -2px;width:0%;height:0.2rem;left: 0;}
.paymentPlan table,.paymentPlan table tr,.paymentPlan table td,.paymentPlan table th{border:none;text-align: center;}
.paymentPlan table{width: 100%;}
.paymentPlan table tr{line-height: 2rem;}
.paymentPlan table tr:first-child{font-size: 0.8rem;font-weight:bold;}
.paymentPlanCon{padding:1rem 0;background: #fff;}
.paymentPlanTips{color: #4086F5;padding-top: 20px;font-size: 16px;text-align: center;}
</style>
